<template>
  <div class="create-blog">
    <template v-if="blogType !== 'xinshu'">
      <b-card v-if="blogUser">
        <b-card-title>
          搜索{{options[blogType].title}}
          <b-link class="float-right small" @click="blogUser = null" v-if="blogUser">
            <fa icon="undo"/>
            重新查找
          </b-link>
        </b-card-title>
        <form class="text-center">
          <div class="mb-2">
            <square :src="blogUser.avatar" style="width: 10rem;" border class="mx-auto"/>
          </div>
          <div>{{blogUser.sourceName}}</div>

          <div class="btn-area">
            <b-btn block variant="primary" @click="makeBlogbook" :disabled="submitting">
              确认是TA，开始制作
            </b-btn>
          </div>
        </form>
      </b-card>
      <b-card :title="`搜索${options[blogType].title}`" v-else>
        <form class="mb-3" @submit.prevent="search">
          <label>在下面输入{{options[blogType].title}}，点击下一步开始搜索</label><br>
          <label v-html="options[blogType].info"></label>
          <b-form-group>
            <b-input :key="blogType"
                     :placeholder="`请输入或粘贴完整的${options[blogType].title}`"
                     required
                     maxlength="64"
                     :disabled="creating"
                     autofocus
                     v-model.trim="account"/>
          </b-form-group>

          <div class="btn-area">
            <b-btn :disabled="!account || creating" block type="submit" variant="primary">
              <fa icon="spinner" spin v-if="creating"/>
              下一步
            </b-btn>
          </div>
        </form>
        <square :src="options[blogType].src"/>
      </b-card>
    </template>
    <template v-else>
      <b-card :title="'创建新的空白书'">
        <div class="example">
          <b-link to="/create/examples">
            基于「模板书」创建
          </b-link>
        </div>
        <form class="mb-3" @submit.prevent="createBlogbook">
          <label>请输入空白书名称</label>
          <b-form-group>
            <b-input autofocus placeholder="输入您想要的书名" required v-model.lazy="newbook.title"/>
          </b-form-group>
          <label>请输入空白书作者</label>
          <b-form-group>
            <b-input placeholder="输入作者名" required v-model.lazy="newbook.author"/>
          </b-form-group>

          <div class="btn-area">
            <b-btn :disabled="canCreate || creating" block type="submit" variant="primary">
              <fa icon="spinner" spin v-if="creating"/>
              点击创建空白书
            </b-btn>
          </div>
        </form>
        <square :src="posterUrl"/>
      </b-card>
    </template>
  </div>
</template>

<script>
import { trim } from 'lodash'

export default {
  name: 'createBlogbook',
  title: '新建长文集',
  data() {
    return {
      options: {
        xinshu: {
          title: '空白书',
          site: 'xinshu',
          src: '',
          info: ''
        },
        weixinmp: {
          title: '微信公众号',
          site: 'weixin',
          src: 'https://img.xinshu.me/upload/c7b0bd7cc5b047ddaa1489684e8c2e82',
          info: ''
        },
        meipian: {
          title: '美篇号',
          site: 'meipian',
          src: 'https://img.xinshu.me/upload/e52f4f15893146618bbec551ab2edeb3',
          info: '您可以在您的昵称下面看到你的美篇号'
        },
        jianshu: {
          title: '简书号',
          site: 'jianshu',
          src: 'https://img.xinshu.me/upload/f7dcfc76d8da48a98195f80212067718',
          info: '简书进入我的主页，网址显示<span class="text-primary">jianshu.com/u/xxxx</span>，xxxx即为您的简书号'
        },
        sinablog: {
          title: '新浪博客号',
          site: 'sina_blog',
          src: 'https://img.xinshu.me/upload/65728ca1ff404694a9c1c15bab297c41',
          info: '新浪博客进入博客主页，网址显示<span class="text-primary">blog.sina.com.cn/u/xxxx</span>，xxxx即为您的新浪博客号'
        },
        sohublog: {
          title: '搜狐博客号',
          site: 'sohu_blog',
          src: 'https://img.xinshu.me/upload/07625d697dfa4169970d17ab896fd358',
          info: '搜狐博客进入我的博客，网址显示<span class="text-primary">xxxx.blog.sohu.com</span>，xxxx即为您的搜狐博客号'
        },
        zhihublog: {
          title: '知乎专栏号',
          site: 'zhihu_blog',
          src: 'https://img.xinshu.me/upload/4899ab752adb480ba18c2e6c04892407',
          info: '知乎专栏点击进入专栏，网址显示<span class="text-primary">zhuanlan.zhihu.com/xxxx</span>，xxxx即为您的知乎专栏号'
        },
        douban: {
          title: '豆瓣号',
          site: 'douban',
          src: 'https://img.xinshu.me/upload/7bc08bb8bb81487aba63267e44c047db',
          info: '豆瓣进入我的豆瓣，网址显示<span class="text-primary">douban.com/people/xxxx</span>，xxxx即为您的豆瓣号'
        },
        lofter: {
          title: '乐乎号',
          site: 'lofter',
          src: 'https://img.xinshu.me/upload/8cc631af6262481499b631a1ebf7eb69',
          info: '您可以在您的昵称下面看到你的乐乎号'
        }
      },
      account: '',
      creating: false,
      blogUser: null,
      submitting: false,
      blogType: '',
      newbook: {
        title: '',
        author: ''
      }
    }
  },
  mounted() {
    if (this.user) {
      this.newbook = {
        title: this.user.nickname + '的空白书',
        author: this.user.nickname
      }
    }
  },
  computed: {
    canCreate() {
      return !trim(this.newbook.title) && !trim(this.newbook.author)
    },
    posterUrl() {
      let avatar = this.user.avatar
      avatar = avatar.replace(/\/\d+$/, '/0')
      return 'https://canvas.xinshu.me/generate/bookdemo' + this.serialize({
        cover: 'cover-23',
        title: this.user.nickname + '的空白书',
        pic: avatar,
        author: this.user.nickname
      })
    }
  },
  watch: {
    // 点击切换
    $route: {
      handler(route) {
        if (route.params.source === 'weixinmp') {
          this.$router.replace('/wxmpassistant')
        }
        this.blogType = route.params.source
        this.account = ''
        this.blogUser = null
      },
      immediate: true
    }
  },
  methods: {
    async createBlogbook() {
      try {
        this.creating = true
        const params = {title: this.newbook.title, author: this.newbook.author}
        const result = await this.$req.post('/blogbook/books/', params)
        result.bookType = 'blogbook'
        this.$alert.success('长文集创建成功')
        this.$router.push('/books/' + this.getBookId(result))
      } catch (err) {
        this.$alert.error('创建失败，请检查书名和作者并重新尝试')
      } finally {
        this.creating = false
      }
    },
    async makeBlogbook() {
      try {
        const sourceSite = this.options[this.$route.params.source].site
        const params = {
          source_site: sourceSite,
          source_id: this.blogUser.sourceId,
          source_name: this.blogUser.sourceName
        }
        this.submitting = true
        await this.$ajax.importSource(params)
        // if (result.status === 'created') {
        const confirmed = await this.$dialog.confirm({
          title: '长文集导入',
          content: `${this.blogUser.sourceName}已开始导入，返回书架稍后即可预览`,
          okOnly: true
        })
        if (!confirmed) {
          return
        }
        this.$router.push('/books?bookType=blogbook')
        // }
      } catch (err) {
        this.submitting = false
      }
    },
    async search() {
      if (!this.account) {
        return
      }
      try {
        const sourceSite = this.options[this.$route.params.source].site
        this.creating = true
        const result = await this.$ajax.fetchSource({sourceSite, sourceId: this.account})
        // 源确实存在
        if (result && result.sourceId && result.sourceName) {
          this.blogUser = {
            sourceId: result.sourceId,
            sourceName: result.sourceName,
            avatar: result.avatar ? result.avatar : require('../../assets/images/anonymous-user.jpg')
          }
        } else { // 源不存在
          this.$alert.error('该用户不存在，请检查输入的ID')
        }
      } catch (err) {
        this.$alert.error('该用户不存在，请检查输入的ID')
      } finally {
        this.creating = false
      }
    },
    goPC() {
      this.$dialog.confirm({
        title: '访问小助手',
        content: '请复制网址 <div class="big"><b>weixinshu.com</b></div> 在电脑上打开',
        okTitle: '我知道了',
        okOnly: true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.create-blog {
  max-width: 640px;

  .example {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }
}
</style>
