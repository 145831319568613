<template>
  <div class="create-wbbook">
    <b-card>
      <div class="text-left card-title">
        <b>授权微博登录</b>
      </div>
      <p>
        授权微博登录后，即可制作您的微博书
        <br>
        仅自己可见和好友圈内容也会一起导出
      </p>

      <div class="btn-area">
        <b-btn block @click="openWindow" variant="primary">
          <fa icon="lock" fw/>
          我知道了，前往授权
        </b-btn>
      </div>

      <square :src="posterUrl"/>
    </b-card>
  </div>
</template>
<script>
export default {
  name: 'createWxbook',
  title: '新建微博书',
  data() {
    return {
      assistantStep: false,
      update: false,
      friendLink: '/create/wbbook/friend'
    }
  },
  created() {
    const query = this.$route.query
    if (query.nickname) {
      let url = '/create/wbbook?nickname=' + query.nickname
      if (query.update) {
        url += '&update=1'
      }
      if (query.bookId) {
        url += '&bookId=' + query.bookId
      }
      if (query.uid) {
        url += '&uid=' + query.uid
      }
      this.$router.replace(url)
    }
  },
  computed: {
    weiboAuthUrl() {
      const query = {
        client_id: 1375343394,
        response_type: 'code',
        redirect_uri: 'https://weixinshu.com/api/book/weibo_auth_callback',
        state: this.user.openid
      }
      return 'https://api.weibo.com/oauth2/authorize' + this.serialize(query)
    },
    posterUrl() {
      return 'https://img.xinshu.me/upload/8b721d77d3f048908a5dac823f2d99f8'
    }
  },
  methods: {
    onLoad() {
      if (this.$route.query.update) {
        this.update = true
        this.friendLink += '?update=true'
      }
    },
    openWindow() {
      if (window.isMobile) {
        location.href = this.weiboAuthUrl
        return
      }

      const w = parseInt(window.innerWidth / 3)
      const h = parseInt(window.innerHeight / 2)
      window.open(this.weiboAuthUrl, '_blank', [
        'toolbar=yes',
        'scrollbars=yes',
        'resizable=yes',
        'top=' + parseInt((window.innerHeight - h) / 3),
        'left=' + parseInt((window.innerWidth - w) / 2),
        'width=' + w,
        'height=' + h
      ])
    }
  }
}
</script>
<style lang="scss" scoped>
  .create-wbbook {
    max-width: 640px;

    .list {
      .item {
        display: block;
        padding: 1em 0;
        border-bottom: 1px solid $hr-border-color;

        &:first-of-type {
          border-top: 1px solid $hr-border-color;
        }

        .left {
          max-width: 56px;
          text-align: center;
          padding-right: 0;
          color: $primary;
          font-size: 2em;
        }

        .center {
          flex-grow: 3;
        }

        .title {
          font-weight: bold;
          margin-bottom: .25em;
          color: $body-color;
        }
      }
    }
  }
</style>
