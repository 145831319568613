<template>
  <div class="create-friend-wbbook">
    <b-card v-if="wbUser">
      <b-card-title>
        {{$route.query.bookId ? '更新好友微博' : '搜索好友做书'}}
        <b-link class="float-right small" @click="wbUser = null" v-if="wbUser">
          <fa icon="undo"/>
          重新查找
        </b-link>
      </b-card-title>

      <form class="text-center">
        <div class="mb-3">
          <avatar round :src="wbUser.avatar" size="6em" plain/>
        </div>

        <h4 class="mb-1">{{wbUser.nickname}}</h4>

        <b-row class="description">
          <b-col>
            <span class="number">{{wbUser.follows}}</span>
            关注
          </b-col>
          <b-col>
            <span class="number">{{wbUser.fans | fans}}</span>
            粉丝
          </b-col>
          <b-col>
            <span class="number">
              {{wbUser.weibos}}
            </span>
            微博
          </b-col>
        </b-row>

        <div class="btn-area">
          <b-btn block variant="primary" @click="makeWbbook" :disabled="submitting">
            {{$route.query.bookId ? '确认是TA，开始更新' : '确认是TA，开始制作'}}
          </b-btn>
        </div>

        <square :src="coverUrl"/>
      </form>
    </b-card>

    <b-card title="搜索好友做书" v-else>
      <form @submit.prevent="searchUser">
        <label>在下面输入好友的微博昵称，点击下一步开始搜索</label>
        <b-form-group>
          <b-input placeholder="请输入或粘贴完整的微博昵称" required maxlength="64" :disabled="loading"
                   autofocus v-model.trim="nickname"/>
        </b-form-group>

        <div class="btn-area">
          <b-btn :disabled="!nickname || loading" block type="submit" variant="primary">
            <fa icon="spinner" spin v-if="loading"/>
            下一步
          </b-btn>
        </div>

        <square src="https://img.xinshu.me/upload/6498ed1ea90f4af5a7e1b8e9a3260811"/>
      </form>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'createFriendWbbook',
  title: '微博送好友',
  data() {
    return {
      nickname: this.$route.query.nickname || '',
      bookId: this.$route.query.bookId || '',
      uid: this.$route.query.uid || '',
      wbUser: null,
      isUpdate: !!this.$route.query.uid,
      loading: false,
      submitting: false
    }
  },
  filters: {
    fans(val) {
      if (val >= 1000000) {
        return Math.ceil(val / 10000) + '万'
      }
      return val
    }
  },
  computed: {
    coverUrl() {
      let avatar = this.wbUser ? this.wbUser.avatar : ''
      avatar = avatar.split('?')[0].replace(/\/crop.+?\//, '/original/')
      return 'https://canvas.xinshu.me/generate/bookdemo' + this.serialize({
        title: this.nickname + '的微博书',
        pic: avatar,
        author: this.nickname
      })
    }
  },
  created() {
    if (this.isUpdate) {
      this.step = 2
      this.searchUser()
    }
  },
  methods: {
    async searchUser() {
      try {
        let url = `/api/book/weibo_user/info?screen_name=${this.nickname.trim()}`
        if (this.isUpdate) {
          url = `/api/book/weibo_user/info?uid=${this.uid.trim()}`
        }
        this.loading = true
        const data = await this.$req.get(url)
        data.nickname = data.screenName
        data.avatar = data.headimgurl
        this.wbUser = data
      } catch (err) {
        this.$alert.error('该用户不存在或已改名')
      } finally {
        this.loading = false
      }
    },
    async makeWbbook() {
      try {
        const url = this.isUpdate ? `/api/book/update_wbbook/${this.bookId}` : `/api/book/make_wbbook/${this.wbUser.uid}`
        this.submitting = true
        const result = await this.$req.get(url)
        if (result.status === 'success') {
          this.$alert.success(`${this.wbUser.nickname}的微博已开始导入，请稍候`)
          const bookId = this.getBookId({
            id: result.bookId || this.bookId,
            bookType: 'wbbook',
            sourceId: this.wbUser.uid
          })
          setTimeout(() => {
            this.$router.push(`/books/${bookId}`)
          }, 2000)
        }
      } catch (err) {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .create-friend-wbbook {
    max-width: 640px;
  }

  .avatar {
    border-radius: 100px;
    border: 2px solid $hr-border-color;
  }

  .description {
    text-align: center;
    color: $text-muted;
    margin: 1em auto;

    .col {
      border-left: 1px solid $hr-border-color;

      &:first-child {
        border-left: 0;
      }
    }

    .number {
      display: block;
      font-size: 1.25em;
      color: $body-color;
      line-height: 1.2;
    }
  }
</style>
