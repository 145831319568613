<template>
  <div class="wxbook-friend">
    <b-card title="导出好友的朋友圈">
      <div>
        ① 电脑访问：<span class="text-primary">weixinshu.com</span> <small>(微信书拼音)</small>
        <br>
        ② 选择导航里的「送好友 / 导私密」
        <br>
        ③ 登录后选择朋友昵称，开始做书
      </div>

      <div class="my-3">
        <b-btn @click="copyLink" variant="primary" block>前往电脑访问</b-btn>
      </div>

      <square inline src="https://img.xinshu.me/upload/c76283d0c38949ee846b4c2466b75d13"/>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'create.wxbookFriend',
  beforeRouteEnter(to, from, next) {
    if (!window.isMobile) {
      return next('/assistant')
    }
    next()
  },
  methods: {
    copyLink() {
      this.$dialog.confirm({
        title: '访问小助手',
        content: '请复制网址 <div class="big"><b>weixinshu.com</b></div> 在电脑上打开',
        okTitle: '我知道了',
        okOnly: true
      })
    }
  }
}
</script>

<style scoped>
  .wxbook-friend {
  }
</style>
