<template>
  <div class="note-previewer">
    <section class="holder" v-if="loading && !html" ref="holder">
      <div>
        <fa icon="book"/>
        正在加载页面
      </div>
    </section>
    <section class="preview-zone" :class="{loading}" v-else>
      <div class="page" v-html="html" v-bind="pageStyle"></div>
    </section>
  </div>
</template>

<script>
import inherits from '@/mixins/inherits'

export default {
  name: 'notePreviewer',
  props: {
    value: {
      required: true
    },
    album: Object,
    pages: {
      type: Array,
      required: true
    },
    editable: Boolean
  },
  mixins: [
    inherits('activeIndex', 'menu')
  ],
  data() {
    return {
      containerW: 0,
      containerH: 0,
      loading: true,
      html: '',
      pageStyle: {}
    }
  },
  computed: {
    page() {
      return this.pages[this.value]
    }
  },
  watch: {
    page: {
      handler: 'init',
      deep: true
    },
    album: {
      handler: 'init',
      deep: true
    },
    '$store.state.winSize': {
      handler: 'init',
      deep: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const page = this.pages[this.value]
      if (page) {
        const size = this.$store.state.winSize[0]
        const p = {...page, title: this.album.name, subtitle: this.album.cover.subtitle}
        this.html = await this.$ajax.fetchPage(p, this.album.innerStyle.id, size)
      }
      this.loading = false
      this.calcSize()
    },
    calcSize() {
      this.pageStyle = {}
      this.$nextTick(() => {
        const pageEl = this.$el.querySelector('section .page')
        const pageWidth = pageEl.clientWidth
        const pageHeight = pageEl.clientHeight
        pageEl.style.display = 'none'

        const zoneEl = this.$el.querySelector('section')
        const zoneWidth = zoneEl.clientWidth - 20
        const zoneHeight = zoneEl.clientHeight - 20

        pageEl.style.display = null

        const scale = Math.min(zoneWidth / pageWidth, zoneHeight / pageHeight)
        this.pageStyle = {
          style: {
            width: Math.round(pageWidth * scale) + 'px',
            height: Math.round(pageHeight * scale) + 'px'
          },
          class: pageWidth > pageHeight ? 'h' : 'v'
        }
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
    this.$root.$off('resize', this.calcSize)
  }
}
</script>

<style scoped lang="scss">
.note-previewer {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .preview-zone {
    padding: 10px;
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;

    &.loading {
      opacity: .5;
    }
  }

  .page {
    background-color: #fff;
    box-shadow: $shadow-lg;
    border: 1px solid $hr-border-color;
    width: 100%;
  }

  .holder {
    position: relative;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
