<template>
  <div class="create-diary">
    <b-card title="创建日记书">
      <div class="example">
        <b-link to="/create/examples">
          基于「模板书」创建
        </b-link>
      </div>
      <form class="mb-3" @submit.prevent="create">
        <label>请输入日记书名称，或使用默认的名称</label>
        <div class="form-group">
          <input :placeholder="defaultTitle"
                 class="form-control"
                 title="作品名称"
                 v-autofocus
                 v-model="title">
        </div>
        <b-btn :disabled="creating" block type="submit" variant="primary">
          <fa icon="spinner" spin v-if="creating"/>
          点击创建日记书
        </b-btn>
      </form>

      <square :src="posterUrl"/>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'createDiaryBook',
  title: '新建日记书',
  data() {
    return {
      title: '',
      creating: false
    }
  },
  computed: {
    defaultTitle() {
      return (this.user.nickname || '我') + '的日记书'
    },
    posterUrl() {
      let avatar = this.user.avatar
      avatar = avatar.replace(/\/\d+$/, '/0')
      return 'https://canvas.xinshu.me/generate/bookdemo' + this.serialize({
        cover: 'cover-23',
        title: this.user.nickname + '的日记书',
        pic: avatar,
        author: this.user.nickname
      })
    }
  },
  methods: {
    async create() {
      try {
        if (this.creating) {
          return
        }
        const confirmed = await this.$dialog.confirm({
          title: '创建作品',
          content: '是否要创建日记书'
        })
        if (!confirmed) {
          return
        }
        this.creating = true
        const bookName = this.title || this.defaultTitle
        const result = await this.$req.post('/api/book/xinshu_diary/book/add', {bookName})
        this.$alert.success('创建成功，正在跳转')
        this.$router.replace('/books/' + this.getBookId(result))
      } catch (err) {
        this.creating = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-diary {
  max-width: 640px;

  .example {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }
}
</style>
