<template>
  <div class="create-qbbbook">
    <b-card>
      <div class="text-left card-title">
        <b>授权亲宝宝登录</b>
      </div>
      <p>
        授权亲宝宝登录后，即可制作或更新您的亲宝宝书
        <br>
        每个宝宝对应一本亲宝宝书
      </p>

      <div class="btn-area">
        <b-btn block @click="goQbbAssistant" variant="primary">
          <fa icon="lock" fw/>
          我知道了，前往授权
        </b-btn>
      </div>

      <square :src="posterUrl"/>
    </b-card>
  </div>
</template>
<script>

export default {
  name: 'createQbbbook',
  title: '新建亲宝宝书',
  data() {
    return {
    }
  },
  computed: {
    posterUrl() {
      return 'https://img.xinshu.me/upload/bbd8723df4ce4271ab115941a7311a72'
    }
  },
  methods: {
    goQbbAssistant() {
      this.$router.push('/qbbassistant')
    }
  }
}
</script>
<style lang="scss" scoped>
  .create-qbbbook {
    max-width: 640px;
  }
</style>
