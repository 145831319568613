<template>
  <div class="create">
    <h2 class="page-title" v-if="isRoute">创建作品</h2>
    <template v-if="!isPartnerUser">
      <b-card v-if="$route.name !== 'create.album'">
        <div class="float-right">
          <b-link class="text-muted" @click="selected = ''" v-if="selected">
            <fa icon="undo"/>
            选择其他来源
          </b-link>
          <template v-else>
            <b-link @click="expanded = !expanded">
              <fa :icon="expanded ? 'caret-up' : 'caret-down'" />
              {{expanded ? '收起' : '查看全部来源'}}
            </b-link>
          </template>
        </div>
        <p class="font-weight-bold" style="font-size: 1.2em;">选择做书的内容来源</p>
        <template v-if="selected !== 'albums'">
          <select-group size="lg"
                        v-model="selected"
                        align="left"
                        cols="2"
                        sm="3"
                        :options="validOptions"/>
          <template v-if="!selected">
            <div class="mt-3 mb-2">公众号或博客成书，请选择</div>
            <select-group size="lg"
                          v-model="selected"
                          align="left"
                          cols="2"
                          sm="3"
                          :options="externalOptions"/>
          </template>
          <template v-if="!selected">
            <div class="mt-3 mb-2">手机相册照片成书，请选择</div>
          </template>
        </template>
        <template v-if="!selected || selected === 'albums'">
          <select-group align="left"
                        size="lg"
                        v-model="selected"
                        cols="2"
                        sm="3"
                        :options="albumOptions"/>
        </template>
      </b-card>
    </template>

    <router-view/>

    <b-btn variant="link" block to="/books" v-if="isRoute">返回书架</b-btn>
  </div>
</template>

<script>

export default {
  name: 'create',
  beforeRouteEnter(to, from, next) {
    if (to.query.agent) {
      location.replace('/pages/agent-home/' + to.query.agent)
      return
    }
    next()
  },
  data() {
    return {
      expanded: !!this.$route.query.expand,
      isRoute: this.$route.name.includes('create'),
      step: 0,
      options: [
        {
          title: '朋友圈',
          value: 'wxbook',
          category: 'wxbook',
          icon: 'https://img.xinshu.me/upload/b1fef140b7ca42e4a68d290b00db5d20'
        },
        {
          title: '朋友圈·导私密',
          value: 'wxbookPrivate',
          category: 'wxbook',
          icon: 'https://img.xinshu.me/upload/2994e1e41da84a57b5731bb86903b1b2',
          hidden: true
        },
        {
          title: '朋友圈·送好友',
          value: 'wxbookFriend',
          category: 'wxbook',
          icon: 'https://img.xinshu.me/upload/4dc02b398bd64d8c83203e235d637d23',
          hidden: true
        },
        {
          title: '新浪微博',
          value: 'wbbook',
          icon: 'https://img.xinshu.me/upload/c37d09832c1744a69c4ffaccfc77d298',
          category: 'wbbook'
        },
        {
          title: '微博·送好友',
          value: 'wbbookFriend',
          icon: 'https://img.xinshu.me/upload/eb3efdcda30d491684df43d783c71275',
          category: 'wbbook',
          hidden: true
        },
        {
          title: '现写日记',
          value: 'diarybook',
          icon: 'https://img.xinshu.me/upload/39b060e777ef46a5b7140e98d70e8f64',
          category: 'diarybook'
        },
        {
          title: 'QQ空间·说说',
          value: 'qbook',
          icon: 'https://img.xinshu.me/upload/69f96960d78b4921bdb0ee502c63e9ba',
          category: 'qbook'
        },

        {
          title: '亲宝宝',
          value: 'qbbbook',
          icon: 'https://img.xinshu.me/upload/cf73bbcafcba4024808d472f63ee02ef',
          category: 'qbbbook'
        },
        {
          title: '宝宝树小时光',
          value: 'bbsbook',
          icon: 'https://img.xinshu.me/upload/d589e1a787a746ffb4aaee88523e16f4',
          category: 'bbsbook',
          hidden: true
        },
        {
          title: '空白书',
          value: 'xinshu',
          icon: 'https://img.xinshu.me/upload/ed6c600f27d04df1bf513fbff23ab226',
          category: 'external'
        },
        {
          title: '美篇',
          value: 'meipian',
          icon: 'https://img.xinshu.me/upload/d389bb80e1b54a47acd1d96f14b1e3d3',
          category: 'external',
          hidden: true
        },
        {
          title: '微信公众号',
          value: 'weixinmp',
          icon: 'https://img.xinshu.me/upload/519702402be14d2584247d11fabacd1f',
          category: 'external'
        },
        {
          title: '简书',
          value: 'jianshu',
          icon: 'https://img.xinshu.me/upload/d072b8d762b64db0bec864a3e933af32',
          category: 'external',
          hidden: true
        },
        {
          title: '新浪博客',
          value: 'sinablog',
          icon: 'https://img.xinshu.me/upload/d68d7f8246554912ac38da5226ffc5bd',
          category: 'external',
          hidden: true
        },
        {
          title: '搜狐博客',
          value: 'sohublog',
          icon: 'https://img.xinshu.me/upload/0cbadd0c492d4fac8ba797f09a486144',
          category: 'external',
          hidden: true
        },
        {
          title: '知乎专栏',
          value: 'zhihublog',
          icon: 'https://img.xinshu.me/upload/94ec7aecf48e4c79b0eb44f892656ce8',
          category: 'external',
          hidden: true
        },
        {
          title: '豆瓣日记',
          value: 'douban',
          icon: 'https://img.xinshu.me/upload/6689d7ef905c432bb713abb364fe2ea8',
          category: 'external',
          hidden: true
        },
        {
          title: '乐乎',
          value: 'lofter',
          icon: 'https://img.xinshu.me/upload/3200849c829f406fb33b3200440988ce',
          category: 'external',
          hidden: true
        },
        {
          title: 'QQ空间·日志',
          value: 'qzbook',
          icon: 'https://img.xinshu.me/upload/69f96960d78b4921bdb0ee502c63e9ba',
          category: 'external',
          tag: 'new'
        },
        {
          title: '照片书',
          icon: 'https://img.xinshu.me/upload/2ef978fa63e7411c9bf839ce258ff1d4',
          value: 'albums',
          category: 'album'
        },
        {
          title: '2022台历',
          icon: 'https://img.xinshu.me/resource/167d75686b1143958aff2e2d99efc2bb',
          value: 'albums?tab=products&product=calendar-2022',
          category: 'album'
        }, {
          title: '实物礼品卡',
          icon: 'https://img.xinshu.me/resource/903239d674f54986a4d90b21d5051889',
          value: 'albums?tab=packages',
          category: 'album',
          tag: 'new'
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(route) {
        const option = this.options.find(i => route.name.endsWith(i.value))
        if (option && option.hidden) {
          this.expanded = true
        }
      },
      immediate: true
    }
  },
  computed: {
    validOptions() {
      const options = this.options
        .filter(i => i.category !== 'external' && i.category !== 'album')
      if (this.selected) {
        const option = this.options.find(i => i.value === this.selected)
        if (option && option.category) {
          return this.options.filter(i => i.category === option.category)
        }
      }
      return options.filter(i => !i.hidden || this.expanded)
    },
    externalOptions() {
      return this.options.filter(i => i.category === 'external').filter(i => !i.hidden || this.expanded)
    },
    albumOptions() {
      return this.options.filter(i => i.category === 'album').filter(i => !i.hidden || this.expanded)
    },
    selected: {
      get() {
        const value = this.$route.name.split('.')[1] || ''
        if (value === 'album') {
          return 'albums'
        }
        if (value === 'blogbook') {
          return this.$route.params.source
        }
        return value
      },
      set(val) {
        if (this.selected === val) {
          return
        }
        if (val === '') {
          return this.$router.push('/create')
        }
        const option = this.options.find(i => i.value === val)
        if (!option) {
          return
        }
        if (option.category === 'album') {
          return this.$router.push('/' + option.value)
        }
        let route = ['create', val].filter(Boolean).join('.')
        if (val === 'weixinmp' || val === 'meipian' || val === 'jianshu' || val === 'sinablog' ||
          val === 'sohublog' || val === 'zhihublog' || val === 'douban' || val === 'lofter' ||
          val === 'xinshu') {
          route = 'create.blogbook'
          this.$router.replace({name: route, params: {source: val}})
          return
        }
        if (route === this.$route.name) {
          return
        }
        this.$router.replace({name: route})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create {
  max-width: 640px;
}
</style>
