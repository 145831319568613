<template>
  <div class="scenario">
    <loading v-if="loading"/>
    <template v-else>
      <b-card class="card-intro" no-body>
        <swiper :options="swiperOptions" style="width: 100%;" ref="swiper">
          <swiper-slide v-if="product.video">
            <video-player class="4:3" :src="product.video" :autoplay="!isAndroid"
                          :poster="product.videoCover || product.carousel[0]"
                          @play="onVideoPlay" @ended="onVideoEnd"/>
          </swiper-slide>
          <swiper-slide v-for="item in product.carousel" :key="item">
            <square height="75%" :src="item"/>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <b-card-body>
          <h4 class="title">{{product.title}}</h4>
          <div class="text-muted">{{product.desc}}</div>
          <div class="tags clearfix">
            <div class="tag float-left mr-2" v-for="tag in product.tags" :key="tag">
              <fa icon="check-circle" far class="text-success"/>
              {{tag}}
            </div>
          </div>
          <div v-if="product.price">
            <span class="price">{{product.price.replace(/\s起$/, '')}}</span>
            <small v-if="product.price.includes('起')" class="text-muted">起</small>
          </div>
          <div class="mt-3" id="btnBuy">
            <b-row>
              <b-col class="mb-3" cols="12" v-if="packageNo">
                <b-btn block :to="'/packages/' + packageNo">
                  <fa icon="gift-card" />
                  购买礼品卡 <small>5折</small>
                </b-btn>
              </b-col>
              <b-col cols="auto" v-if="!isPartnerUser">
                <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                  <fa icon="home"/>
                </b-btn>
                <b-btn block @click="$router.go(-1)" v-else>
                  <fa icon="chevron-left"/>
                </b-btn>
              </b-col>
              <b-col>
                <b-btn block v-b-modal="'create'" variant="primary" :disabled="creating">
                  创建作品
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>

      <bottom-bar sm target="#btnBuy">
        <div class="content p-3 mx-auto" style="max-width: 640px;">
          <b-row>
            <b-col cols="auto" v-if="!isPartnerUser">
              <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                <fa icon="home"/>
              </b-btn>
              <b-btn block @click="$router.go(-1)" v-else>
                <fa icon="chevron-left"/>
              </b-btn>
            </b-col>
            <b-col>
              <b-btn block v-b-modal="'create'" variant="primary" :disabled="creating">
                创建作品
              </b-btn>
            </b-col>
          </b-row>
        </div>
      </bottom-bar>

      <b-card title="产品详情" v-if="product.presentations && product.presentations.length">
        <div class="presentations">
          <img :src="img.pic"
               :alt="img.title"
               v-for="img in product.presentations"
               :key="img.pic">
        </div>
      </b-card>

      <b-modal title="创建作品" ok-title="确认" @ok="onSubmit" id="create" size="sm">
        <p>在下面输入您喜欢的作品名称，或使用默认的名称</p>
        <b-input :placeholder="defaultTitle" v-model="albumTitle"/>
      </b-modal>
    </template>
  </div>
</template>

<script>
import route from '@/mixins/route-data'
import { configShare } from '@/modules/wechat'

export default {
  name: 'album.scenario',
  mixins: [
    route('scenarios'),
    route('albumProducts'),
    require('@/mixins/swiper').default
  ],
  data() {
    return {
      albumTitle: '',
      creating: false,
      swiperOptions: {
        autoplay: {
          delay: 6000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    packageNo() {
      return {
        xsalbum: 'TMQGXQ',
        xcalbum: 'AGMPRD'
      }[this.product.tid]
    },
    defaultTitle() {
      const defaultTitle = this.product?.defaultTitle || '我的照片书'
      return defaultTitle.replace('我', this.user?.nickname || '我')
    },
    product() {
      const result = this.scenarios.find(i => i.id === this.$route.params.id)
      if (result) {
        const product = this.albumProducts.find(i => i.tid === result.tid)
        result.price = product.price
        result.defaultTitle = product.defaultTitle
      }
      return result
    }
  },
  methods: {
    onLoad() {
      configShare({
        title: this.product.title,
        desc: this.product.desc,
        link: 'https://weixinshu.com/scenarios/' + this.$route.params.id,
        imgUrl: this.product.carousel[0]
      })
    },
    async onSubmit() {
      try {
        if (!this.product) {
          return
        }
        if (this.creating) {
          return
        }
        this.creating = true
        const {tid, innerStyle, cover} = this.product
        const data = {
          tid,
          name: this.albumTitle || this.defaultTitle,
          meta: {
            nickname: this.user.nickname,
            avatar: this.user.avatar,
            date: this.$day().format('YYYY-MM-DD')
          }
        }
        if (innerStyle) {
          data.innerStyle = {id: innerStyle}
        }
        if (cover) {
          data.cover = {codeName: cover}
        }
        // 检测未使用的兑换码
        const codes = await this.$req.get('/api/user/redeem_codes', {params: {tid, used: 0}})
        if (codes?.length) {
          const use = await this.$dialog.confirm({
            title: '使用兑换码',
            content: '您有当前产品的兑换码尚未使用，是否立刻使用？',
            cancelTitle: '暂不使用'
          })
          if (use) {
            data.redeemCode = codes[0].code
          }
        }
        const album = await this.$req.post('/jianxiang/api/1/albums/', data)
        if (this.albumTitle) {
          await this.$req.put('/jianxiang/api/1/albums/' + album.aid + '/', {
            name: this.albumTitle
          })
        }
        this.$router.replace('/albums/' + album.aid)
      } catch (err) {
        console.error(err.message)
        this.creating = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.scenario {
  max-width: 640px;

  .fixed-bottom {
    border-top: 1px solid $hr-border-color;
    background-color: #fff;

    .content {
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
      max-width: 640px;
    }
  }

  .tags {
    padding-top: .5em;
    padding-bottom: .5em;
    white-space: nowrap;
    color: $text-muted;
  }

  .price {
    font-size: 1.5em;
    color: $primary;
  }

  .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .template + .template {
    margin-top: 1.5rem;
  }
}
</style>
