<template>
  <div class="albums">
    <page-title class="d-sm-block d-none">照片书</page-title>
    <b-row>
      <b-col cols="4" sm="3">
        <b-nav vertical class="side-menu sticky-top" style="top: 10px;">
          <b-nav-item @click="switchTo('')" :active="tab === ''">
            <fa icon="fire" class="icon" fw/>
            热门推荐
          </b-nav-item>
          <b-nav-item @click="switchTo('products')" :active="tab === 'products'">
            <fa icon="book" class="icon" fw/>
            产品类型
            <fa icon="caret-right" fad class="float-right mt-1 pr-2"/>
          </b-nav-item>
          <b-nav v-if="tab === 'products'" vertical class="sub-nav">
            <b-nav-item v-for="item in products"
                        :active="product === item.tid"
                        :key="item.id"
                        replace
                        :to="item.link || '?tab=products&product=' + item.tid"
                        exact>
              {{item.alias || item.title}}
            </b-nav-item>
          </b-nav>
          <b-nav-item @click="switchTo('scenarios')" exact :active="tab === 'scenarios'">
            <fa icon="image" class="icon" fw/>
            适用场景
            <fa icon="caret-right" fad class="float-right mt-1 pr-2"/>
          </b-nav-item>
          <b-nav v-if="tab === 'scenarios'" vertical class="sub-nav">
            <b-nav-item v-for="item in categories"
                        :active="category === item.id"
                        :key="item.id"
                        replace
                        :to="item.link || '?tab=scenarios&category=' + item.id"
                        exact>
              {{item.title}}
            </b-nav-item>
          </b-nav>
          <b-nav-item @click="switchTo('packages')" :active="tab === 'packages'">
            <fa icon="gift" class="icon" fw/>
            套装礼包
            <fa icon="caret-right" fad class="float-right mt-1 pr-2"/>
          </b-nav-item>
          <b-nav v-if="tab === 'packages'" vertical class="sub-nav">
            <b-nav-item :active="!category" replace to="?tab=packages" exact>实物礼品卡</b-nav-item>
            <b-nav-item :active="category === 'electronic'" replace
                        to="?tab=packages&sub=electronic" exact>
              电子卡券
            </b-nav-item>
          </b-nav>
          <b-nav-item @click="switchTo('customize')" :active="tab === 'customize'">
            <fa icon="building" class="icon" fw/>
            商务定制
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col style="min-width: 0;" class="pl-0">
        <div class="mb-3" v-if="tab === ''">
          <swiper class="swiper" :options="swiperOptions" style="width: 100%;">
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <swiper-slide v-for="item in banners" :key="item.pic">
              <b-link :to="item.url">
                <square :src="item.pic" height="32.5%"/>
              </b-link>
            </swiper-slide>
          </swiper>
        </div>

        <template v-if="loading">
          <b-row>
            <b-col class="item" v-for="i in 6" :key="i" sm="6" lg="4">
              <b-row align-v="center">
                <b-col cols="4" sm="12">
                  <ske type="square" :height="isSmallScreen ? '100%' : '75%'"/>
                </b-col>
                <b-col>
                  <h6 class="mt-1 mb-1">
                    <ske width="8em"></ske>
                  </h6>
                  <div class="small text-muted">
                    <ske block width="70%"></ske>
                  </div>
                  <div class="mt-1">
                    <span class="text-primary">
                      <ske width="2em"></ske>
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="tab === 'customize'">
          <square src="https://img.xinshu.me/resource/f9cd19e290fe4475aa623dcd126d0ce1" height="184%"/>
        </template>
        <template v-else-if="tab === 'packages' && !sub">
          <b-row>
            <b-col class="item" sm="6" v-for="item in giftcards" :key="item.id">
              <b-link class="mb-3" :to="getRoute(item)">
                <square height="75%"
                        mode="contain" style="background-color:#f9f9f9;"
                        :src="item.hero" :alt="item.title" :data-tag-sm="item.badge" class="primary"/>
                <h6 class="mt-3 mb-1">{{item.title}}</h6>
                <div class="small text-muted">{{item.shortDesc}}</div>
                <div>
                  <span class="text-primary">{{getPrice(item)}}</span>
                  <span class="text-muted" v-if="item.oriPrice"
                        style="text-decoration: line-through">{{item.oriPrice}}</span>
                </div>
              </b-link>
            </b-col>
          </b-row>
        </template>
        <template v-else-if="currentItems.length">
          <b-row>
            <b-col v-for="item in currentItems" :key="item.id" sm="6" lg="4" class="item">
              <b-link :to="getRoute(item)">
                <b-row align-v="center">
                  <b-col cols="5" sm="12">
                    <square :height="isSmallScreen ? '100%' : '75%'"
                            mode="contain" style="background-color:#f9f9f9;"
                            :src="item.hero" :alt="item.title" :data-tag-sm="item.badge" class="primary"/>
                  </b-col>
                  <b-col>
                    <h6 class="mt-sm-3 mb-1">{{item.title}}</h6>
                    <div class="small text-muted">{{item.shortDesc}}</div>
                    <div>
                      <span class="text-primary">{{getPrice(item)}}</span>
                      <span class="text-muted" v-if="item.oriPrice"
                            style="text-decoration: line-through">{{item.oriPrice}}</span>
                    </div>
                  </b-col>
                </b-row>
              </b-link>
            </b-col>
          </b-row>
        </template>
        <template v-else>
          <empty icon="box-open">暂无相关项目哦</empty>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { configShare } from '@/modules/wechat'

export default {
  name: 'album.scenarios',
  title: '创建照片书',
  mixins: [
    require('@/mixins/swiper').default
  ],
  data() {
    return {
      loading: true,
      swiperOptions: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      tab: '',
      categories: [],
      packages: [],
      products: [],
      specials: [],
      banners: []
    }
  },
  async created() {
    this.tab = this.$route.query.tab || ''
    const [
      {banners},
      products,
      specials,
      scenarios,
      categories,
      packages
    ] = await this.$req.get('/buckets/' + [
      'xiangce:home',
      'xiangce:products',
      'xiangce:specials',
      'xiangce:scenarios',
      'xiangce:categories',
      'weixinshu:packages'
    ].join(','), {baseURL: 'https://ark.xinshu.me'})

    this.specials = specials.map(id => scenarios.find(i => i.id === id)).filter(Boolean)
    this.scenarios = scenarios.filter(i => !i.disabled)
    this.products = products.filter(i => !i.disabled)
    this.categories = categories.filter(i => !i.disabled)
    this.packages = packages.filter(i => !i.disabled)
    this.giftcards = packages.filter(i => i.title.includes('礼品卡'))

    const categoryIds = categories.map(i => i.id)
    this.scenarios = this.scenarios.sort((a, b) => {
      return categoryIds.indexOf(a.category) - categoryIds.indexOf(b.category)
    })

    if (banners.length) {
      for (const item of banners) {
        const {pic, url, start, end} = item
        if (start && this.$day().isBefore(start)) {
          continue
        }
        if (end && this.$day().isAfter(end)) {
          continue
        }
        this.banners.push({url, pic})
      }

      if (banners.length <= 1) {
        this.swiperOptions.pagination = {}
        this.swiperOptions.allowTouchMove = false
        this.swiperOptions.allowSlidePrev = false
        this.swiperOptions.allowSlideNext = false
      }
    }
    this.loading = false
  },
  computed: {
    category: {
      get() {
        return this.$route.query.category || this.categories?.[0]?.id || ''
      },
      set(val) {
        this.$router.push({
          name: this.$route.name,
          query: {
            category: val
          }
        })
      }
    },
    product: {
      get() {
        return this.$route.query.product || this.products?.[0]?.tid || ''
      },
      set(val) {
        this.$router.push({
          name: this.$route.name,
          query: {
            product: val
          }
        })
      }
    },
    sub() {
      return this.$route.query.sub || ''
    },
    currentItems() {
      const {tab, sub} = this.$route.query
      if (tab === 'scenarios') {
        return this.scenarios.filter(i => i.category === this.category)
      }
      if (tab === 'packages') {
        return sub === 'electronic' ? this.packages : this.giftcards
      }
      if (tab === 'products') {
        return this.scenarios.filter(i => i.tid === this.product)
      }
      return this.specials
    }
  },
  watch: {
    category(val, oldVal) {
      if (!oldVal && val) {
        this.onLoad()
      }
    }
  },
  methods: {
    onLoad() {
      setTimeout(() => {
        const nav = this.$refs.nav
        if (nav) {
          nav.scrollTop = this.$el.querySelector('.nav-link.active')?.parentElement?.offsetTop
        }
      })

      this.categories = this.categories.filter(i => !i.disabled)

      configShare({
        title: '心书·照片书',
        desc: '快来跟我一起，用相片记录感动',
        link: 'https://weixinshu.com/albums',
        imgUrl: 'https://img.xinshu.me/upload/fc61cd0db332446abd385c807c0df4fe'
      })
    },
    switchTo(tab) {
      if (this.tab === tab) {
        this.tab = null
      } else {
        if (this.$route.query.tab !== tab) {
          this.$router.push({query: {tab: tab || undefined}})
        }
        this.tab = tab
      }
    },
    getRoute(item) {
      if (item.packageNo) {
        return '/packages/' + item.packageNo
      }
      return '/scenarios/' + item.id
    },
    getPrice(item) {
      if (item.price) {
        return item.price
      }
      const product = this.products.find(i => i.tid === item.tid)
      return product?.price
    }
  }
}
</script>

<style data-page="albums">
main {
  padding-bottom: 0 !important;
}
</style>

<style scoped lang="scss">
.albums {
  max-width: 800px;
}

.side-menu {
  border-radius: $border-radius;
  min-height: calc(#{100vh} - 30px);
  border-right: 1px solid #f2f2f2;
  overflow-y: auto;
  overflow-x: hidden;

  @include media-breakpoint-up(sm) {
    height: calc(#{100vh} - 150px);
  }

  .nav-link {
    color: #333;
    border-bottom: 1px solid #f2f2f2;
    padding-top: .75em;
    padding-bottom: .75em;

    &.active {
      color: $primary;
    }
  }

  .sub-nav {
    .nav-link {
      transition: background-color .3s;
      font-size: 13px;
      padding-right: 5px;
      padding-left: 20px;

      @include media-breakpoint-up(sm) {
        padding-left: 2.5rem;
      }

      &:hover {
        background-color: #f2f2f2;
      }

      &.active {
        position: relative;
        background-color: #f2f2f2;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 4px;
          background-color: $primary;
          border-radius: 2px;
        }
      }
    }
  }
}

.swiper {
  box-shadow: $box-shadow-sm;
  border-radius: $border-radius;
}

.icon {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.item {
  a {
    display: block;
    color: $body-color;
  }

  &:last-of-type {
    .row {
      border-bottom: 0;
    }
  }

  .row {
    padding-bottom: 1rem;
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid #f2f2f2;
      padding-top: 1rem;
    }
  }
}
</style>
