import { render, staticRenderFns } from "./Wxbook.vue?vue&type=template&id=da7519b4&scoped=true&"
import script from "./Wxbook.vue?vue&type=script&lang=js&"
export * from "./Wxbook.vue?vue&type=script&lang=js&"
import style0 from "./Wxbook.vue?vue&type=style&index=0&id=da7519b4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da7519b4",
  null
  
)

export default component.exports