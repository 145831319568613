<template>
  <div class="create-wxbook">
    <b-card v-if="loading">
      <b-card-title>
        <ske width="5em"/>
      </b-card-title>

      <div class="text-center">
        <div>
          <ske width="10em"></ske>
        </div>
        <div>
          <ske width="10em"></ske>
        </div>
        <div>
          <ske width="10em"></ske>
        </div>
        <div>
          <ske width="10em"></ske>
        </div>
        <div>
          <ske width="10em"></ske>
        </div>
      </div>
    </b-card>

    <b-card title="添加小编做书" v-else-if="!hasBook || $route.query.create">
      <div class="text-center">
        <section v-if="qrcode">
          <div class="mb-2">
            <b>扫描下方二维码添加小编做书</b>
            <br>
            {{weixinId}}
          </div>
          <div>
            <square :src="qrcode" class="qrcode-image mx-auto"/>
          </div>
        </section>

        <section v-else>
          <div class="mb-2">
            <div>点击下方复制小编微信号添加做书</div>
            <div class="weixin" @click="copyWeixin">{{weixinId}}</div>
          </div>
          <swiper class="guide" :options="swiperOptions">
            <swiper-slide v-for="i in guides" :key="i">
              <img :src="i" alt="">
            </swiper-slide>
          </swiper>
        </section>

        <div class="warning mt-2">
          <b>注意需开放朋友圈哦</b>
          <br>
          <span class="text-danger">请勿设置三天或半年可见</span>
          <br>
          否则无法制作全部内容
        </div>

        <transition name="fade">
          <div class="mt-3 mx-auto" style="max-width: 240px;" v-if="needHelp">
            <b-btn block pill variant="primary" @click="call4Help" v-if="!help">
              我还是不会做，请小编加我
            </b-btn>

            <b-btn block pill variant="primary" @click="call4Help"
                   v-else-if="help.status === 'not_found'">
              未能添加成功，重新输入
            </b-btn>

            <b-btn block pill disabled variant="primary" v-else>
              <span v-if="help.status === 'init'">小编正在加你，请留意微信</span>
              <span v-else-if="help.status === 'req_sent'">小编已发送申请</span>
              <span v-else-if="help.status === 'added'">已经添加成功，请等待排版</span>
            </b-btn>
          </div>
        </transition>
      </div>
    </b-card>

    <b-card title="更新微信书" v-else>
      <div class="text-center">
        <section v-if="qrcode">
          <div class="mb-2">
            <b>扫描下方二维码添加小编更新</b>
            <br>
            {{weixinId}}
          </div>
          <div>
            <square :src="qrcode" class="qrcode-image mx-auto"/>
          </div>
        </section>

        <section v-else>
          <div class="mb-2">
            <div>点击下方小编微信号复制添加更新</div>
            <div class="weixin" @click="copyWeixin">{{weixinId}}</div>
          </div>
          <swiper class="guide" :options="swiperOptions">
            <swiper-slide v-for="i in guides" :key="i">
              <img :src="i" alt="">
            </swiper-slide>
          </swiper>
        </section>

        <div class="mt-2">
          <b>如果您已经添加，可以直接在微信中向小编发送「更新」，小编会为您更新内容</b>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import copyText from 'clipboard-copy'
import routeData from '@/mixins/route-data'

export default {
  name: 'createWxbook',
  title: '新建微信书',
  mixins: [routeData('xiaobian'), require('@/mixins/swiper').default],
  data() {
    return {
      help: null,
      hasBook: false,
      needHelp: false,
      imageLoaded: false,
      swiperOptions: {
        effect: 'fade',
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        }
      },
      guides: [
        'https://img.xinshu.me/upload/7f57d4da1a254353963759b9b6861b42',
        'https://img.xinshu.me/upload/f6bccb739a2a4f07b40d52d484c628fd',
        'https://img.xinshu.me/upload/0de9470dc4004d01949efe1d9ece1751'
      ]
    }
  },
  computed: {
    qrcode() {
      return this.xiaobian.qrcode
    },
    weixinId() {
      return this.xiaobian.xiaobian
    }
  },
  methods: {
    onLoad() {
      this.hasBook = this.xiaobian.hasBook
      // 通过 displayMethod 判断使用哪种添加方式
      // 其实最好使用一个比例值 0-1 控制显示方式 (2020-09-04)
      if (this.xiaobian.displayMethod !== 'qrcode') {
        this.xiaobian.qrcode = ''
      }
    },
    copyWeixin(e) {
      copyText(this.weixinId).then(() => {
        this.$alert.success('小编微信号已复制，请按照图片所示步骤添加')
      }).catch(() => {
        this.$alert.error('微信号复制失败，请手动复制后，按照图片所示步骤添加')
      })

      const range = document.createRange()
      range.selectNode(e.target)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
    },
    async call4Help() {
      const contact = await this.$dialog.prompt({
        title: '请求小编帮助',
        content: '请输入您的手机号或微信号，小编会主动添加您的微信',
        placeholder: '手机号或微信号',
        maxlength: '32'
      })

      if (contact) {
        this.help = await this.$req.post('/api/wxbook/helps', {contact})
        this.$alert.success('申请已提交，小编将会稍后添加您的微信，请您留意微信好友申请')
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
}
</script>
<style lang="scss" scoped>
.qrcode-image {
  max-width: 200px;
  border: 2px solid $hr-border-color;
  border-radius: $border-radius;
}

@keyframes guideLoop {
  0% {
    background-image: url(https://img.xinshu.me/upload/ab4fed3f167c4820ac2bdf2feb78e2de);
  }

  33.3% {
    background-image: url(https://img.xinshu.me/upload/b526c4859029412ab99cdbeac4d94f5f);
  }

  66.6% {
    background-image: url(https://img.xinshu.me/upload/f21d73aa6ef34e54aa5b24d27d559755);
  }
}

.weixin {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  font-size: 2em;
  padding: .5rem 2rem;
  margin: .5rem auto;
  background-color: $hr-border-color;
  border: 1px solid $hr-border-color;
  border-radius: 8px;
  font-family: monospace;
}

.guide {
  background-size: 100%;
  border-radius: 4px;
  margin-bottom: 1rem;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
</style>
