<template>
  <div class="create-bbsbook">
    <b-card>
      <div class="text-left card-title">
        <b>授权宝宝树小时光登录</b>
      </div>
      <p>
        授权宝宝树小时光登录后，即可制作或更新您的小时光书
        <br>
        每个小家对应一本小时光宝书
      </p>

      <div class="btn-area">
        <b-btn block @click="goQbbAssistant" variant="primary">
          <fa icon="lock" fw/>
          我知道了，前往授权
        </b-btn>
      </div>

      <square :src="posterUrl"/>
    </b-card>
  </div>
</template>
<script>

export default {
  name: 'createBbsbook',
  title: '新建宝宝树小时光书',
  data() {
    return {
    }
  },
  computed: {
    posterUrl() {
      return 'https://img.xinshu.me/upload/ce150122222b4e808c5c2244a8964f3c'
    }
  },
  methods: {
    goQbbAssistant() {
      this.$router.push('/bbsassistant')
    }
  }
}
</script>
<style lang="scss" scoped>
  .create-bbsbook {
    max-width: 640px;
  }
</style>
