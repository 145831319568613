<template>
  <div :data-tid="tid" class="album-previewer">
    <section class="holder" v-if="loading && !html">
      <div>
        <fa icon="book"/>
        正在加载页面
      </div>
    </section>
    <section class="preview-zone" :class="{loading}" v-else>
      <div class="page" :class="['page-' + value]" v-bind="pageStyle">
        <cover plain :book="{...album, cover}" v-if="value === 'cover'"
               :size="$store.state.winSize[0]"/>
        <div class="page-content" v-html="html" v-else></div>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchPage } from '@/models/album'
import inherits from '@/mixins/inherits'

export default {
  name: 'albumPreviewer',
  mixins: [
    inherits('menu')
  ],
  props: {
    pages: {
      type: Array,
      required: true
    },
    value: {
      required: true
    },
    album: Object,
    editable: Boolean
  },
  data() {
    return {
      loading: false,
      pageStyle: {},
      slides: [],
      html: ''
    }
  },
  watch: {
    page: {
      handler: 'init',
      deep: true
    },
    '$store.state.winSize': {
      handler: 'calcSize',
      deep: true
    }
  },
  computed: {
    tid() {
      return this.album.tid?.replace?.('wm-', '') || ''
    },
    page() {
      return this.pages[this.value]
    },
    innerStyle() {
      return this.album?.innerStyle?.id
    },
    cover() {
      const cover = {...this.album.cover}
      cover.subtitle = cover.subtitle || '献给那些美好的回忆'
      return cover
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      const index = this.value
      const page = this.pages[index]
      const innerStyle = this.album.innerStyle.id
      if (page) {
        this.loading = true
        this.html = await fetchPage(page, innerStyle, this.$store.state.winSize[0])
        this.loading = false
      }
      this.calcSize()
    },
    calcSize() {
      this.pageStyle = {}
      this.$nextTick(() => {
        const pageEl = this.$el.querySelector('section .page')
        const pageWidth = pageEl.clientWidth
        const pageHeight = pageEl.clientHeight
        pageEl.style.display = 'none'

        const zoneEl = this.$el.querySelector('section')
        const zoneWidth = zoneEl.clientWidth - 20
        const zoneHeight = zoneEl.clientHeight - 20

        pageEl.style.display = null

        const scale = Math.min(zoneWidth / pageWidth, zoneHeight / pageHeight)
        this.pageStyle = {
          style: {
            width: Math.round(pageWidth * scale) + 'px',
            height: Math.round(pageHeight * scale) + 'px'
          },
          class: pageWidth > pageHeight ? 'h' : 'v'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.album-previewer {
  padding: 10px;
  user-select: none;
  display: flex;
  flex-direction: column;

  &[data-tid="salbum"], &[data-tid="xsalbum"] {
    .page.page-cover {
      box-shadow: none;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .preview-zone {
    width: 100%;
    flex-grow: 1;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity .3s;
    opacity: 1;

    &.loading {
      opacity: .5;
      pointer-events: none;
    }
  }

  .holder {
    flex-grow: 1;
    position: relative;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page {
    position: relative;
    background-color: #fff;
    box-shadow: $shadow-lg;
    border: 1px solid $hr-border-color;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    width: 100%;

    .page-content {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
