<template>
  <div class="frame-previewer" :data-tid="album.tid">
    <section class="preview-zone">
      <div class="page" v-html="page" v-bind="pageStyle"></div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'framePreviewer',
  props: {
    pages: {
      type: Array,
      required: true
    },
    album: Object,
    value: Number,
    editable: Boolean
  },
  data() {
    return {
      page: '',
      pageStyle: {},
      options: {
        spaceBetween: 30,
        slidesPerView: 1,
        keyboard: true
      },
      loading: false
    }
  },
  watch: {
    '$store.state.winSize': {
      handler: 'calcSize',
      deep: true
    },
    pages: {
      handler: 'init',
      deep: true
    },
    'album.meta': {
      handler: 'init',
      deep: true
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      const page = this.pages[0] || {name: '001'}
      page.meta = this.album.meta
      this.page = await this.$ajax.fetchPage(
        page,
        this.album.innerStyle.id,
        this.$store.state.winSize[0]
      )
      this.loading = false
      this.calcSize()
    },
    calcSize() {
      this.pageStyle = {}
      this.$nextTick(() => {
        const pageEl = this.$el.querySelector('section .page')
        const pageWidth = pageEl.clientWidth
        const pageHeight = pageEl.clientHeight
        pageEl.style.display = 'none'

        const zoneEl = this.$el.querySelector('section')
        const zoneWidth = zoneEl.clientWidth * 0.8
        const zoneHeight = zoneEl.clientHeight * 0.8

        pageEl.style.display = null

        const scale = Math.min(zoneWidth / pageWidth, zoneHeight / pageHeight)
        this.pageStyle = {
          style: {
            width: Math.round(pageWidth * scale) + 'px',
            height: Math.round(pageHeight * scale) + 'px'
          },
          class: pageWidth > pageHeight ? 'h' : 'v'
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.frame-previewer {
  .preview-zone {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .holder {
    flex-grow: 1;
    position: relative;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .page {
    position: relative;
    width: 100%;
    height: auto;
    background-color: #fff;
    box-shadow: $shadow-lg;
    border: 1px solid $hr-border-color;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -14%;
      left: -17%;
      right: -17%;
      bottom: -14%;
      background-image: url("https://img.xinshu.me/upload/cc8c3ded6aec416399c1358f60461aed!800");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center center;
      pointer-events: none;
    }
  }
}
</style>
