<template>
  <div class="examples">
    <b-card no-body class="overflow-hidden">
      <div>
        <square src="https://img.xinshu.me/upload/2d558256f41f4c8b9c69010ca19d0487"
                height="32.5%"/>
      </div>
    </b-card>
    <b-card no-body>
      <b-tabs card justified>
        <b-tab v-for="tab in tabs" :key="tab.key" :title="tab.value">
          <b-row>
            <b-col sm="6" v-for="item in books[tab.key]" :key="item.id">
              <book-item :value="item">
                <template slot="cover">
                  <b-link class="cover-link" :to="to(item)"
                          :class="item.type">
                    <cover :book="item" fill="auto" size="320">
                    </cover>
                  </b-link>
                </template>
                <template slot="title">
                  {{item.title || item.name}}
                </template>
                <template slot="info">
                  <div class="text-overflow">
                    <b-badge variant="muted">{{getCategory(item)}}</b-badge>
                    <b-badge variant="muted">{{item.pages || item.pageCount}}页</b-badge>
                  </div>
                </template>
                <template slot="action">
                  <b-btn :to="to(item)">预览</b-btn>
                  <b-btn class="ml-2" :disabled="alreadyCopy.indexOf(item.id || item.aid) > -1" variant="primary" @click="copy(item)">{{alreadyCopy.indexOf(item.id || item.aid) > -1 ? '已复制' : '一键复制'}}</b-btn>
                </template>
              </book-item>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-btn variant="link" block @click="$router.go(-1)" v-if="$store.state.prevRoute">返回</b-btn>
    <b-btn variant="link" block to="/books" v-else>返回书架</b-btn>
  </div>
</template>

<script>
import { get } from 'lodash'
import BookItem from '@/pages/Book/BookItem'
import { bookProducts } from '@/config'

export default {
  title: '心书素材库',
  components: {
    BookItem
  },
  data() {
    return {
      books: {},
      tabs: [
        {
          key: 'boyfriend',
          value: '送男友'
        },
        {
          key: 'girlfriend',
          value: '送女票'
        },
        {
          key: 'bestfriend',
          value: '送闺蜜'
        },
        {
          key: 'other',
          value: '其他'
        }
      ],
      tab: {},
      alreadyCopy: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    getCategory(item) {
      if (item.tid) {
        return '文绘集'
      } else {
        return get(bookProducts, [item.bookType, [0]]) || '心书'
      }
    },
    to(value) {
      return '/books/' + this.getBookId(value)
    },
    init() {
      this.fetchBooks().then(data => {
        this.books = data
      })
      this.tab = this.tabs[0]
    },
    fetchBooks() {
      return this.$req.get('/buckets/weixinshu:library')
    },
    async copy(item) {
      const comfirm = await this.$dialog.confirm({
        title: '复制作品',
        content: '您确定要复制这本书到自己的书架吗？',
        okTitle: '确定',
        cancelTitle: '取消'
      })
      if (!comfirm) {
        return
      }
      const { id, bookType, tid } = item
      if (tid) {
        console.log(tid)
        const result = await this.$req.post('/jianxiang/api/1/albums/', {
          aid: item.aid,
          tid: item.tid
        }).catch(() => null)
        this.alreadyCopy.push(item.aid)
        if (!result) {
          this.$alert.error('您已经复制过本书')
          return
        }
      } else {
        if (bookType === 'blogbook') {
          const result = await this.$req.post('/blogbook/books/', { copy: id }).catch(() => null)
          this.alreadyCopy.push(id)
          if (!result) {
            this.$alert.error('您已经复制过本书')
            return
          }
        } else {
          const result = await this.$req.post('/api/book/xinshu_diary/book/copy', {
            bookId: Number(id)
          }).catch(() => null)
          this.alreadyCopy.push(id)
          if (!result) {
            this.$alert.error('您已经复制过本书')
            return
          }
        }
      }

      const goShelt = await this.$dialog.confirm({
        title: '复制作品',
        content: '复制成功，请到书架查看(复制书籍可能有所延迟，请耐心等待)',
        okTitle: '前往书架',
        cancelTitle: '关闭'
      })

      if (goShelt) {
        this.$router.push('/books')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.examples {
  max-width: 640px;

  ::v-deep .book-title {
    font-size: 1.2rem;
  }
}
</style>
