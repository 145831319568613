<template>
  <div class="qq-book">
    <template v-if="status === -1">
      <empty icon="car-crash">初始化失败，刷新页面试试吧</empty>
      <b-btn block @click="$router.go(0)">刷新页面</b-btn>
    </template>

    <loading v-else-if="status === 0"/>

    <template v-else-if="status === 1">
      <b-card title="验证 QQ">
        <form @submit.prevent="confirmQQ">
          <label>请输入您的QQ号进行确认</label>
          <b-form-group>
            <b-input placeholder="请输入QQ号确认" maxlength="15" autofocus v-model="account.uin"/>
          </b-form-group>
          <div class="btn-area">
            <b-btn block type="submit" variant="primary" :disabled="submitting">下一步</b-btn>
          </div>
        </form>
      </b-card>
    </template>

    <template v-else-if="status === 2">
      <b-card title="">
        <b-card-title>
          <b-link class="float-right small" @click="reset">
            <fa icon="undo"/>
            重新输入QQ
          </b-link>
          验证 QQ
        </b-card-title>
        <div class="user-info text-center">
          <div class="mb-2">
            <square :src="account.avatar" style="width: 10rem;" border class="mx-auto"/>
          </div>
          <div>{{account.uin}}</div>
        </div>

        <div class="btn-area">
          <b-btn block @click="bind" variant="primary">确定是我，开始制作</b-btn>
        </div>
      </b-card>
    </template>

    <template v-else-if="status === 3">
      <template v-if="isWechat">
        <b-card>
          <b-card-title>
            <b-link class="float-right small" @click="reset">
              <fa icon="undo"/>
              重新输入QQ
            </b-link>
            说说书需要在QQ中制作
          </b-card-title>

          <img src="https://img.xinshu.me/upload/ee7fb3ce5b7d4faab90a49accb957e10"
               class="arrow">
          <ol class="text-left py-3 mb-0">
            <li>
              点击右上角...，选择
              <img
                src="https://img.xinshu.me/upload/3df4c3bd630442de95b7eb3fdbd1fb09!160"
                alt="icon">
              <b>分享到QQ</b>
            </li>
            <li>
              选择
              <img src="https://img.xinshu.me/upload/ed6b197cb0db4fb483e1ac4572659c0a"
                   alt="icon">
              <b>我的电脑</b>，点击<b>发送</b>
            </li>
            <li>选择<b>留在QQ</b>，点击链接</li>
          </ol>
          <img src="https://img.xinshu.me/upload/2652e7606d5f4ee39ed0f7f466214ea7"
               alt="操作说明">
        </b-card>
      </template>

      <b-card v-else-if="!selected">
        <b-card-title>
          <b-link class="float-right small" @click="reset">
            <fa icon="undo"/>
            重新输入QQ
          </b-link>
          暂无可用 QQ 小助手
        </b-card-title>

        <div class="text-center">
          <p>
            <emotion value="cry" style="max-width: 160px;"/>
          </p>
          <h5 v-if="!spiders.length">暂无可用小助手</h5>
          <h5 v-else>所有小助手都在使用中</h5>
          <div>您可以在此等待，有可用后可立即使用</div>
          <div class="text-muted mt-2">
            <fa icon="spinner-third" spin/>
            正在为您查询可用小助手
          </div>
        </div>
      </b-card>

      <b-card v-else>
        <b-card-title>
          <b-link class="float-right small" @click="reset">
            <fa icon="undo"/>
            重新输入QQ
          </b-link>
          扫码制作说说书
        </b-card-title>

        <div class="text-center">
          <template v-if="selected.qrcodeB64">
            <p>
              <template v-if="isQQ">长按下方二维码<br>在弹出的菜单中选择「扫描二维码」</template>
              <template v-else>打开手机QQ，点击右上角加号<br>选择「扫一扫」即可</template>
            </p>

            <p>
              <square class="mx-auto" :src="selected.qrcodeB64" style="max-width: 160px;"/>
            </p>

            <div>
              <template v-if="isWechat">扫码后请返回微信内查看电子书</template>
              <template v-else-if="isQQ">扫码后请返回微信内查看电子书</template>
              <template v-else>扫码后请在此等待</template>
            </div>
          </template>

          <template v-else>
            <emotion value="wink" style="max-width: 200px;"/>
            <div class="mt-2">当前小助手使用中...<br>请稍候</div>
          </template>
        </div>
      </b-card>
    </template>

    <template v-else-if="status === 4">
      <b-card title="制作说说书">
        <div class="text-center">
          <div>正在为您制作《{{account.nickname || account.uin}}的说说书》</div>
          <p v-if="account.progress">
            已经排版
            <span class="text-primary">{{account.progress | percent}}</span>
          </p>
          <p v-else>正在初始化</p>
          <square height="76.8%" style="max-width: 200px;" class="mx-auto"
                  src="https://img.xinshu.me/upload/9a62d6f8ea4f477f922db9a4a2dd9dd3"/>
          <div class="mt-3">完成后可在「书架 -> 说说书」内查看</div>
        </div>
      </b-card>
    </template>

    <template v-else-if="status === 5">
      <b-card title="导出完成">
        <div class="text-center">
          <emotion value="wink" style="max-width: 200px;"/>
          <div class="mt-3" v-if="account.finishTime">
            完成于{{account.finishTime * 1000 | moment('calendar')}}
          </div>
          <div>作品更新可能会有延迟<br>请稍后返回书架查看作品</div>
        </div>
        <div class="btn-area">
          <b-btn block variant="primary" to="/books?bookType=shuoshuo_book">查看作品</b-btn>
          <b-btn block @click="reset">重新导出</b-btn>
        </div>
      </b-card>
    </template>
  </div>
</template>

<script>
import { chain } from 'lodash'
import { configShare } from '@/modules/wechat'
import reload from '@/mixins/reload-needed'

export default {
  name: 'shuoshuoBook',
  title() {
    return this.$route.query.sourceId ? '更新说说书' : '制作说说书'
  },
  mixins: [reload],
  data() {
    return {
      statuses: {
        AVAILABLE: '空闲',
        FETCH_DATA: '使用中'
      },
      spiders: [],
      selected: null,
      status: 0,
      account: {
        progress: 0,
        avatar: '',
        nickname: '',
        uin: this.$route.query.sourceId || ''
      },
      isQQ: /QQ/i.test(navigator.userAgent),
      submitting: false,
      loginLink: location.href.split('#')[0].split('?')[0] + '?token=' + this.$store.state.token
    }
  },
  async created() {
    if (this.$route.query.sourceId) {
      await this.bind()
    }
    try {
      this.status = 1
      await this.init()
      if (this.status === 4) {
        this.poll()
      }
    } catch (err) {
      console.error(err)
      this.status = -1
    } finally {
      configShare({
        title: '制作说说书专属链接，不要发给其他人哦',
        desc: '仅用以制作说说书',
        link: this.loginLink,
        imgUrl: 'https://img.xinshu.me/upload/3df4c3bd630442de95b7eb3fdbd1fb09'
      })
    }
  },
  methods: {
    reset() {
      this.status = 1
      clearInterval(this.interval)
    },
    poll() {
      clearInterval(this.interval)
      let polling = false
      this.interval = setInterval(async () => {
        try {
          if (polling) {
            return
          }

          if (this.status <= 2) {
            return
          }

          polling = true
          await this.init()
        } finally {
          polling = false
        }
      }, 2000)
    },
    async init() {
      let {spiders, user} = await this.$req.get('/api/book/shuoshuo/spiders')
      spiders = chain(spiders).map(i => {
        if (i.qrcodeB64) {
          i.qrcodeB64 = 'data:image/png;base64,' + i.qrcodeB64
        }
        if (i.totalPage) {
          i.totalPage = Number(i.totalPage) || 0
        }
        if (i.currPage) {
          i.currPage = Math.max(1, Number(i.currPage)) || 1
        }
        return i
      }).orderBy('expireIn', 'desc').value()

      if (user.status === 'FETCH_DATA') {
        this.status = 4
        this.selected = chain(spiders).find({uin: user.uin}).value()
      } else if (user.status === 'FINISH') {
        this.status = 5
        clearInterval(this.interval)
      } else {
        this.selected = spiders.find(i => i.status === 'AVAILABLE' && i.expireIn > 0)
      }

      if (this.selected?.totalPage) {
        this.account.progress = this.selected.currPage / this.selected.totalPage
      }

      if (user.uin) {
        this.account.uin = user.uin
      }

      this.spiders = spiders
    },
    async bind() {
      if (!/^\d{5,12}$/.test(this.account.uin)) {
        return this.$alert.error('QQ号格式错误')
      }
      await this.$req.post('/api/book/shuoshuo/users', {uin: this.account.uin})
      await this.init()
      this.status = 3
      this.poll()
    },
    async confirmQQ() {
      clearInterval(this.interval)
      this.account.avatar = await this.getAvatar(this.account.uin)
      this.status = 2
    },
    async getAvatar(qq) {
      for (let i = 5; i > 1; i--) {
        const src = 'https://q1.qlogo.cn/g?b=qq&nk=' + qq + '&s=' + i
        const {w, h} = await this.loadImage(src)
        if (w && h && (w !== 40 || h !== 40)) {
          return src
        }
      }
      const link = `http://qlogo1.store.qq.com/qzone/${qq}/${qq}/640`
      return this.$img(link, 640)
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
.qq-book {
  max-width: 640px;

  img[alt="icon"] {
    height: 1.25em;
  }

  .arrow {
    position: absolute;
    right: -.5rem;
    top: -.5rem;
    width: 5em;
  }
}
</style>
