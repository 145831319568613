<template>
  <div class="create-album">
    <template v-if="!tid">
      <b-card>
        <p>未指定产品编号，请重新进入</p>
        <b-btn block @click="$router.go(-1)">返回</b-btn>
      </b-card>
    </template>
    <template v-else-if="loading">
      <b-card no-body>
        <ske type="square" height="75%"/>
        <b-card-body>
          <ske width="7em" style="font-size: 2em;"/>
          <ske width="100%"/>
          <ske width="100%"/>
          <ske width="5em" block/>
          <ske width="8em" style="font-size: 1.25em;"/>
          <ske width="100%" type="button"/>
        </b-card-body>
      </b-card>
      <b-card>
        <ske width="5em" style="font-size: 1.5em;"/>
        <ske type="page"/>
      </b-card>
    </template>
    <template v-else-if="tid === 'salbum' && !code">
      <b-card>
        <empty icon="book">需要兑换码才能创建作品哦</empty>
        <b-btn block to="/workshop/redeem">前往输入兑换码</b-btn>
      </b-card>
    </template>
    <template v-else>
      <template v-if="step === 0">
        <b-card class="card-intro" no-body>
          <swiper :options="swiperOptions" style="width: 100%;" ref="swiper">
            <swiper-slide v-if="product.video">
              <video-player class="4:3" :src="product.video" :autoplay="!isAndroid"
                            :poster="product.videoCover || product.carousel[0]"
                            @play="onVideoPlay" @ended="onVideoEnd"/>
            </swiper-slide>
            <swiper-slide v-for="item in product.carousel" :key="item">
              <square height="75%" :src="item"/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <b-card-body>
            <h4 class="title">{{product.title}}</h4>
            <div class="text-muted">{{product.desc}}</div>
            <div class="tags clearfix" v-if="products.tags">
              <div class="tag float-left mr-2" v-for="tag in product.tags" :key="tag">
                <fa icon="check-circle" far class="text-success"/>
                {{tag}}
              </div>
            </div>
            <div class="mb-2" v-if="product.price">
              <span class="price">{{product.price | rmb}}</span>
              <b-badge variant="warning" v-if="product.profit">
                会员返佣 {{product.profit | rmb}}
              </b-badge>
              <b-badge variant="warning" v-else-if="product.discountPrice">
                会员价 {{product.discountPrice | rmb}}
              </b-badge>
            </div>
            <div class="mb-3" v-if="inviterGrade">
              <div class="alert alert-warning" v-if="grade">
                您是{{grade === 'gold' ? '金牌' : '皇冠'}}会员，下单可获得返佣
              </div>
              <b-link href="https://weimeng.weixinshu.com/join" target="_blank"
                      class="alert alert-warning d-block" v-else>
                开通会员，享会员折扣
                <span class="float-right">马上开通 ></span>
              </b-link>
            </div>
            <div class="mt-3">
              <b-row v-if="showRedeem">
                <b-col>
                  <b-btn block @click="step = 1">直接创建</b-btn>
                </b-col>
                <b-col>
                  <b-btn block variant="primary" :to="'/redeem?tid=' + product.tid">使用兑换码</b-btn>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="auto" v-if="!isPartnerUser">
                  <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                    <fa icon="home"/>
                  </b-btn>
                  <b-btn block @click="$router.go(-1)" v-else>
                    <fa icon="chevron-left"/>
                  </b-btn>
                </b-col>
                <b-col>
                  <b-btn block @click="step = 1" variant="primary" :disabled="creating" id="btnBuy">
                    下一步
                  </b-btn>
                </b-col>
              </b-row>
            </div>
          </b-card-body>
        </b-card>

        <bottom-bar sm target="#btnBuy">
          <div class="content p-3 mx-auto" style="max-width: 640px;">
            <b-row>
              <b-col cols="auto" v-if="!isPartnerUser">
                <b-btn block to="/books?bookType=albums" v-if="!$store.state.prevRoute">
                  <fa icon="home"/>
                </b-btn>
                <b-btn block @click="$router.go(-1)" v-else>
                  <fa icon="chevron-left"/>
                </b-btn>
              </b-col>
              <b-col>
                <b-btn block @click="step = 1" variant="primary" :disabled="creating">
                  下一步
                </b-btn>
              </b-col>
            </b-row>
          </div>
        </bottom-bar>

        <b-card title="产品详情" v-if="product.presentations && product.presentations.length">
          <div class="presentations">
            <img :src="img.pic"
                 :alt="img.title"
                 v-for="img in product.presentations"
                 :key="img.pic">
          </div>
        </b-card>
      </template>

      <template v-if="step === 1">
        <b-card no-body>
          <b-nav tabs fill v-if="tabs.length">
            <b-nav-item :active="tab === i" @click="tab = i" v-for="i in tabs" :key="i">
              {{i}}
            </b-nav-item>
          </b-nav>
          <b-card-body>
            <b-link class="float-right text-muted" to="/create/albums" v-if="!isPartnerUser">
              <fa icon="undo"/>
              重新选择产品
            </b-link>
            <b-card-title>
              选择模板
            </b-card-title>
            <b-row class="template" v-for="item in templates" :key="item.title" align-v="center">
              <b-col cols="5" sm="3">
                <square mode="contain" :src="item.pic" @click="goConfirm(item)"/>
              </b-col>
              <b-col>
                <h5 class="mt-0">{{item.title}}</h5>
                <div class="desc">{{item.desc}}</div>
                <div class="mt-2">
                  <b-btn v-if="item.sample" @click="showSample(item)">
                    查看示例
                  </b-btn>
                  <b-btn variant="primary" @click="goConfirm(item)">选择模板</b-btn>
                </div>
              </b-col>
            </b-row>

            <empty v-if="!templates.length">暂无可选模板</empty>
          </b-card-body>
        </b-card>

        <b-modal id="sample" hide-footer @hidden="onHidden" @shown="onShown" size="lg"
                 :title="templateName + '模板示例'">
          <div class="previewer">
            <empty icon="book">正在载入示例...</empty>
          </div>
        </b-modal>
      </template>

      <template v-if="step === 2">
        <b-card no-body class="overflow-hidden">
          <square :src="selected.pic" style="max-width: 300px;" class="mx-auto" height="auto"/>
          <b-card-body class="border-top">
            <form @submit.prevent="create">
              <b-form-group>
                <template slot="label">您已选择 <b>{{selected.title}}</b> 作为模板</template>
                <b-input v-model.trim="albumName" placeholder="在这里填入作品名称（不填则使用默认的名称）"/>
              </b-form-group>
              <b-btn block variant="primary" type="submit" :disabled="creating">确认创建</b-btn>
            </form>
          </b-card-body>
        </b-card>
        <div class="btn-area">
          <b-btn block @click="step--" variant="link">上一步</b-btn>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'createAlbum',
  title: '新建照片书',
  components: {VideoPlayer},
  mixins: [
    require('@/mixins/swiper').default
  ],
  data() {
    return {
      loading: false,
      creating: false,
      grade: '',
      inviterGrade: '',
      step: parseInt(this.$route.query.step) || 0,
      tab: '',
      products: [],
      albumTemplates: [],
      templateName: '',
      sampleId: null,
      selected: null,
      albumName: '',
      code: this.$route.query.code || '',
      samples: {},
      swiperOptions: {
        autoplay: {
          delay: 6000
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  computed: {
    showRedeem() {
      return ['xsalbum', 'xcalbum'].includes(this.product.tid)
    },
    tid() {
      return this.$route.query.tid || ''
    },
    templates() {
      return this.albumTemplates
        .filter(i => i.tid === this.tid && (i.type || '') === this.tab && !i.disabled)
    },
    tabs() {
      const tabs = []
      this.albumTemplates.filter(i => !i.disabled && i.tid === this.tid).forEach((i) => {
        if (i.type && !tabs.includes(i.type)) {
          tabs.push(i.type)
        }
      })
      return tabs
    }
  },
  async created() {
    if (!this.tid) {
      return
    }
    try {
      this.loading = true
      let products = []

      if (this.tid.startsWith('wm-')) {
        products = await this.$ajax.fetchWeimengProducts()

        const rules = await this.$req.get('/api/weimeng/products/price_rules')
        const user = await this.$req.get('/api/weimeng/users/info')

        this.grade = user.weimengAgent?.category
        this.inviterGrade = user.inviter?.category

        const priceRules = rules.products
        const profitRules = rules[this.grade || user.inviter?.category] || {}

        products = products.filter(i => !i.disabled).map(i => {
          i.price = priceRules[i.tid]?.basePrice || i.price
          if (this.grade) {
            i.profit = profitRules[i.tid]?.parentAgentAlbumProfit
          } else {
            i.discountPrice = i.price - profitRules[i.tid].parentAgentAlbumProfit
          }
          return i
        })
        this.albumTemplates = await this.$ajax.fetchWeimengTemplates()
      } else {
        products = await this.$ajax.fetchAlbumProducts()
        this.albumTemplates = await this.$ajax.fetchAlbumTemplates()
      }

      this.product = products.find(i => i.tid === this.tid)
      if (!this.product) {
        this.product = {
          tid: this.tid,
          title: this.tid,
          desc: '这是一款未上线/不存在的产品，仅供测试',
          carousel: [
            'https://img.xinshu.me/upload/409345a63c7f475c9e784ba26dcd31dd'
          ]
        }
      }
    } finally {
      this.loading = false
      this.$nextTick(() => {
        this.onLoad()
      })
    }
  },
  methods: {
    onLoad() {
      this.$setTitle('新建' + this.product.title)
      if (this.step === 1 && !this.code) {
        this.step = 0
      }

      this.tab = this.tabs[0] || ''

      const tplName = this.$route.query.template
      if (tplName) {
        const template = this.albumTemplates.find(i => i.tid === this.tid && i.title === tplName)
        if (template) {
          this.selected = template
          this.step = 2
        } else {
          this.selected = {
            tid: this.tid,
            title: tplName,
            pic: ''
          }
        }
      }
    },

    onVideoPlay() {
      const swiper = this.$refs.swiper.$swiper
      swiper.autoplay.stop()
    },
    onVideoEnd() {
      const swiper = this.$refs.swiper.$swiper
      if (swiper.activeIndex === 0) {
        swiper.slideNext()
        swiper.autoplay.start()
      }
    },

    async showSample(item) {
      this.sampleId = item.sample
      this.templateName = item.title
      this.$bvModal.show('sample')
    },
    async onShown() {
      const albumId = this.sampleId
      const productsMap = {
        calendar: 'CalendarPreviewer',
        'calendar-h': 'CalendarPreviewer',
        'calendar-v': 'CalendarPreviewer',
        'calendar-metal': 'CalendarPreviewer',
        calbum: 'CAlbumPreviewer',
        b5album: 'CAlbumPreviewer'
      }
      let component = productsMap[this.tid] || productsMap[this.tid.replace('wm-', '')]
      if (!component) {
        component = 'AlbumPreviewer'
      }
      let propsData = this.samples[albumId]
      if (!propsData) {
        propsData = {
          album: await this.$ajax.fetchAlbum({albumId}),
          pages: await this.$ajax.fetchAlbumPages({albumId}),
          editable: false
        }
        propsData.pages = propsData.pages.slice(0, 24)
        this.samples[albumId] = propsData
      }
      this.previewer = new Vue({
        ...require('@/pages/Previewer/' + component).default,
        parent: this,
        propsData
      })
      const el = this.previewer.$mount().$el
      el.classList.add('previewer')
      document.querySelector('.previewer').innerHTML = ''
      document.querySelector('.previewer').appendChild(el)
    },
    onHidden() {
      this.previewer && this.previewer.$destroy()
    },
    async redeem() {
      try {
        const code = this.code.toUpperCase()
        this.creating = true
        const result = await this.$req.post('/api/redeem_code/validate', {
          redeemCode: code,
          tid: this.tid
        })
        if (result.aid) {
          return this.$router.push('/albums/' + result.aid)
        }
        this.step = 1
      } catch (err) {
      } finally {
        this.creating = false
      }
    },
    async goConfirm(item) {
      if (item.tid === 'salbum') {
        const confirmed = await this.$dialog.confirm({
          title: '选择模板',
          content: '选择模板并创建后将无法修改，建议您先查看示例，是否确定选择<b>' + item.title + '</b>',
          okTitle: '确认选择'
        })
        if (!confirmed) {
          return
        }
      }
      if (item.tid === 'xcalbum' && this.code) {
        const confirmed = await this.$dialog.confirm({
          title: '选择模板',
          content: '选择模板后将不能更改，同时兑换码将被使用。',
          okTitle: '确认'
        })
        if (!confirmed) {
          return
        }
      }
      this.selected = item
      this.step = 2
    },
    async create() {
      try {
        if (!this.selected) {
          return
        }
        if (this.creating) {
          return
        }
        this.creating = true
        const tid = this.tid
        let name = this.selected.defaultTitle || this.product.defaultTitle || '我的照片书'
        if (this?.user?.nickname) {
          name = name.replace('我', this.user.nickname)
        }
        if (this.albumName) {
          name = this.albumName
        }
        const data = {
          tid,
          name
        }
        if (tid === 'frame-moment') {
          data.meta = {
            nickname: this.user.nickname,
            avatar: this.user.avatar,
            date: this.$day().format('YYYY-MM-DD')
          }
        }
        if (this.selected.innerStyle) {
          data.innerStyle = {id: this.selected.innerStyle}
        }
        if (this.selected.cover) {
          data.cover = {codeName: this.selected.cover}
        }
        if (this.code) {
          data.redeemCode = this.code
        } else {
          // 检测未使用的兑换码
          const codes = await this.$req.get('/api/user/redeem_codes', {params: {tid, used: 0}})
          if (codes?.length) {
            const use = await this.$dialog.confirm({
              title: '使用兑换码',
              content: '您有当前产品的兑换码尚未使用，是否立刻使用？',
              cancelTitle: '暂不使用'
            })
            if (use) {
              data.redeemCode = codes[0].code
            }
          }
        }
        const album = await this.$req.post('/jianxiang/api/1/albums/', data)
        if (this.albumName) {
          await this.$req.put(
            '/jianxiang/api/1/albums/' + album.aid + '/',
            {name: this.albumName}
          )
        }
        this.$router.replace('/albums/' + album.aid)
      } catch (err) {
        console.error(err.message)
        this.creating = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.create-album {
  max-width: 640px;

  .fixed-bottom {
    border-top: 1px solid $hr-border-color;
    background-color: #fff;

    .content {
      margin-left: auto;
      margin-right: auto;
      padding: 1rem;
      max-width: 640px;
    }
  }

  .tags {
    padding-top: .5em;
    padding-bottom: .5em;
    white-space: nowrap;
    color: $text-muted;
  }

  .price {
    font-size: 1.5em;
    color: $primary;
  }

  .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .template + .template {
    margin-top: 1.5rem;
  }
}

iframe {
  width: 100%;
  height: 70vh;
  background-color: #fff;
  border: 0;
}

.badge {
  vertical-align: text-bottom;
}
</style>
